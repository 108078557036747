.upcoming-episodes {
    background-color: #3b1252;
    color: #ffffff;
    width: 100%;
    max-width: 1200px; /* Set the maximum width */
  }
  
  .upcoming-episodes h2 {
    text-align: center;
  }
  
  .plan-note {
    font-size: 0.8em;
    font-weight: normal;
  }
  
  .timeline {
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    flex-wrap: wrap;
  }
  
  .timeline-item::before {
    content: '';
    position: absolute;
    left: 10px;
    width: 3px;
    height: 100%;
    background: #1f66e0;
    z-index: -1;
  }
  
  .timeline-item:first-child::before {
    top: 50%;
    height: 50%;
  }
  
  .timeline-item:last-child::before {
    height: 50%;
  }
  
  .timeline-icon {
    width: 20px;
    height: 20px;
    background-color: #f7e017;
    border: 3px solid #1f66e0;
    border-radius: 50%;
    margin-right: 20px;
    z-index: 1;
  }
  
  .timeline-content {
    flex-grow: 1;
    border-left: 3px solid #1f66e0;
    padding-left: 20px;
    position: relative;
    max-width: 80%; /* Ensure the content stays relative to the window */
  }
  
  .timeline-content h3 {
    margin: 0;
  }
  
  .timeline-content p {
    margin: 0;
    font-weight: bold;
  }
  
  .timeline-content img {
    margin-top: 10px;
    max-width: 300px;
    width: 100%;
    border: 2px solid #1f66e0;
    border-radius: 5px;
    margin-left: 20px; /* Space between text and image */
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .timeline-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .timeline-content {
      border-left: none;
      padding-left: 0;
      max-width: 100%;
    }
  
    .timeline-item::before {
      left: 50%;
      top: 20px;
      width: 100%;
      height: 3px;
      background: #1f66e0;
      z-index: -1;
    }
  
    .timeline-item:first-child::before {
      width: 50%;
      left: 50%;
    }
  
    .timeline-item:last-child::before {
      width: 50%;
      left: 0;
    }
  
    .timeline-icon {
      margin: 10px 0;
    }

    
  }
  