@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

.App {
text-align: center;
background-color: #583D65; /* Set background color to purple */
min-height: 100vh; /* Ensure it covers the full viewport height */
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: white; /* Change text color to white for better contrast */
}



.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-image {
display: flex;
flex-direction: column;
padding: 15px;
width: 250px;
background-color: #583D65;
object-fit: cover;
}

.patreon-logo{
  height: 12px;
  width: 10px;

}

.grid-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 20px; /* Add some space between columns */
  justify-content: left; /* Center columns horizontally */
  padding-left: 6%;
  padding-bottom: 20px;
}

.column {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 2px solid white;
  border-radius: 10px;
  box-sizing: border-box;
  width: 18%; /* Maximum 5 columns per row */
  justify-content: center;
}

li {
  margin: 0;
}

.game-link{
  color: inherit;
  text-decoration: inherit;
}

.thelist{
  list-style-type: none; /* Remove default list style */
  padding: 0;
  margin: 30px;
  border: 6px solid #6CBAEA;; /* Add a border around the list */
  background-color: white;
}


.timeline{
padding-left: 100px;
display: flex;
width: 100%;
flex-wrap: wrap; 
}



/* Styles for the header container */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.thelistHeader {
  font-size: 24px;
  font-weight: bold;
}

.additionalText {
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  max-width: 600px; /* Adjust as necessary */
}

/* Styles for responsiveness */
@media (max-width: 1000px) {
  .additionalText {
    text-align: center;
  }
}

.game-item:hover {
  color: lightgray;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .links {
    flex-direction: column;
    align-items: flex-start;
  }
  .grid-container {
    flex-direction: column; /* Switch to a single column layout */
    align-items: center; /* Center items vertically */
    padding-left: 0px;
    padding-bottom: 20px;
  }
  li {
   
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: 65px;
    margin-right: 65px;
    width:70%;
  }

  .game-link{
    flex-direction: column; /* Switch to a single column layout */
    align-items: center; /* Center items vertically */
  }

  .column {
    width: 100%; /* Each column takes up full width */
  }
}

.game-number {
  font-weight: bold; /* Make the numbers bold */
  margin-right: 5px;
}


.btn {
  position: relative;
  height: 50px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
}

.btnr {
  position: relative;
  height: 50px;
  text-decoration: none;
  cursor: pointer;
}

.fa-bluesky {
  font-size: 15px; /* Adjust icon size */
  margin-right: 10px; /* Spacing between icon and text */
  vertical-align: top; /* Fix vertical alignment */
  line-height: 1; /* Ensure consistent line height */
  position: relative; /* Allows fine-tuning of position */
  top: 1px; /* Adjust to align the icon perfectly */
}

i {
  font-style: italic;
}

.footer {
  font-size:x-small;
  margin-top: auto;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
}

.footer-link {

  color: white;
  text-decoration: none;
  padding: 5px 10px;
  background-color: black;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.footer-link:hover {
  background-color: darkgray;
  color: white;
}

.upcoming-header {
  color: white;
  padding-left: 20px !important;

}

.vertical-timeline {
}

.vertical-timeline-element-content {
  background:#6CBAEA!important;
  color: #3c3c3c;
  
}

.vertical-timeline-element-icon{
  -webkit-box-shadow: 0 0 0 6px #6CBAEA, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;;
  box-shadow: 0 0 0 6px #6CBAEA, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;;
}

.vertical-timeline-element-date {
  color: White !important;
  font-size:xx-large;

}

/* Height adjustment for timeline based on episode count */
.vertical-timeline::before {
  width: 6px !important;
  top:30px !important;
  height: 10% ;
  

  
  
}

.timeline-image {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: auto;
  border: 6px solid #6CBAEA;;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 20px;
}

@media (max-width: 200px) {
  .vertical-timeline {
    flex-direction: column;
    margin-left: 20px !important;
  }
}

 .vertical-timeline-element-content {
      -webkit-box-shadow: 0 0px 0 #f7e017 !important;
      box-shadow: 0 0px 0 #f7e017 !important;
    }


